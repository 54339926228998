import { ConfigProvider, Select } from "antd";
import styles from "./style.module.scss";
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, MarkFilledIcon } from "assets";
import React, { MutableRefObject, ReactElement, useRef, useState } from "react";
import { DefaultOptionType } from "rc-select/lib/Select";

const { Option } = Select;


const StyledSelect = (props: StyledSelectProps) => {
  const [isOpen, setOpen] = useState(false);
  const getActiveOpitonContentElement = (rootElement: HTMLElement | null) => {
    if (rootElement) {
      const activeOptionElement = rootElement.getElementsByClassName(
        'ant-select-item-option-active',
      )?.[0];
      if (activeOptionElement) {
        return activeOptionElement.getElementsByClassName(
          'ant-select-item-option-content',
        )?.[0];
      }
    }
    return void 0;
  };

  const dropdownWrapperElementRef = useRef<HTMLDivElement | null>(null);
  const inputSinceClear = useRef<string | undefined>(undefined);

  return <ConfigProvider
    theme={props.alternativeStyle ? {
      components: {
        Select: {
          colorBgContainer: "#18191C",
          colorBgElevated: "#18191C",
          colorPrimaryHover: ""
        }
      }
    } : {}}
  >
    <Select
      ref={props.reff}
      className={styles.input}
      disabled={!(props.enabled ?? true)}
      showSearch={props.enableSearch}
      allowClear={props.enableClear}
      style={{ width: props.width }}
      defaultValue={props.defaultValue}
      value={props.value}
      placeholder={props.defaultHint}
      onChange={props.onChange}
      onClear={() => {
        inputSinceClear.current = "";
        props.onClear?.();
      }}
      filterOption={props.optionsFilter ?? true}
      onInputKeyDown={event => {
        const keysToIgnore = [
          "Shift", "Control", "Alt", "Meta",
          "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight",
          "Tab", "CapsLock", "Escape", "Enter", "Backspace", "Delete",
        ];
        if (!keysToIgnore.includes(event.key)) {
          if ((props.inputFilter?.(event.key) ?? false)) {
            event.preventDefault();
          } else {
            if (inputSinceClear.current === undefined) {
              inputSinceClear.current = "";
            }
            inputSinceClear.current += event.key;
          }
        }
      }}
      clearIcon={<img src={MarkFilledIcon} alt="" />}
      suffixIcon={<img src={isOpen ? ChevronUpIcon : ChevronDownIcon} alt="" />}
      menuItemSelectedIcon={<img src={CheckIcon} alt="" />}
      onDropdownVisibleChange={(status) => setOpen(status)}
      // ---- [implementation of tabbing to select active option] start -----
      dropdownRender={(originNode) => {
        return (
          <div ref={dropdownWrapperElementRef}>
            {props.customElementDropdown ?? originNode}
          </div>
        );
      }}
      onBlur={(...args) => {
        if (props.alternativeStyle || !isOpen) return;

        if (inputSinceClear.current === "") {
          inputSinceClear.current = undefined;
          return;
        }

        const activeOptionContentElement = getActiveOpitonContentElement(
          dropdownWrapperElementRef.current,
        );
        if (
          activeOptionContentElement && props.data.length > 0
        ) {
          const activeOption = props.data.find((el) => {
            if (typeof el === "object") {
              return (el.props.value === activeOptionContentElement.innerHTML);
            } else {
              return (el === activeOptionContentElement.innerHTML);
            }
          });
          if (activeOption && props.onChange) {
            const newValue = typeof activeOption === "object" ? activeOption.props.value : activeOption;
            props.onChange(newValue);
          }
        }
      }}
      // ---- [implementation of tabbing to select active option] end -----
    >
      {props.data.map((entry, index) => {
        if (typeof entry === "object") {
          return <Option key={`styledSelectEntry-${index}`} value={entry.props.value}>
            {entry}
          </Option>;
        }
        return <Option key={`styledSelectEntry-${index}`} value={entry}>{entry}</Option>;
      })}
    </Select>
  </ConfigProvider>;
};

type StyledSelectProps = {
  reff?: MutableRefObject<any>
  data: (string | React.JSX.Element)[]
  optionsFilter?: (value: string, data?: DefaultOptionType) => boolean
  value?: string
  enabled?: boolean
  onChange?: (value: string) => void
  onClear?: () => void
  inputFilter?: (value: string) => boolean
  defaultValue?: string
  defaultHint?: string
  labelSuffix?: string
  enableSearch?: boolean
  enableClear?: boolean

  //Used for top selectors
  alternativeStyle?: boolean

  //Used for table and other components in future maybe
  customElementDropdown?: ReactElement

  //Used for header things for now, TODO maybe rewrite later
  width?: string
}

const Entry = (props: EntryProps) => {
  return <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
    {React.isValidElement(props.icon) ? props.icon :
      props.icon?.startsWith("https://") ?
        <img src={props.icon} alt="" width={40} height={40} /> :
        <img src={props.icon} alt="" />
    }
    {props.label}
  </div>;
};

type EntryProps = {
  value: string
  label?: string
  icon?: string | ReactElement
}

StyledSelect.Entry = Entry;

export default StyledSelect;