import React, { useEffect, useRef, useState } from "react";

import { Layout } from "antd";
import styles from "./style.module.scss";
import SidebarComponent from "./components/SidebarComponent";
import NewConnectionComponent from "./components/SidebarComponent/components/NewConnectionComponent";
import PathfindingComponent from "./components/SidebarComponent/components/PathfindingComponent";
import InformationComponent from "./components/SidebarComponent/components/InformationComponent";
import PortalerCreatorsComponent from "./components/SidebarComponent/components/PortalerCreatorsComponent";
import HelpComponent from "./components/SidebarComponent/components/HelpComponent";
import SettingsComponent from "./components/SidebarComponent/components/SettingsComponent";
import FooterComponent from "sharedComponents/FooterComponent";
import HeaderComponent from "./components/HeaderComponent";
import {
  ArrowsToCenterIcon,
  CircleInfoFilledIcon,
  CircleInfoIcon, CircleQuestionFilledIcon, CircleQuestionIcon, CogFilledIcon, CogIcon,
  LocationPlusFilledIcon,
  LocationPlusIcon,
  TrophyFilledIcon, TrophyIcon
} from "assets";
import PortalMapComponent from "pages/MainPage/components/PortalMapComponent";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchPortalMaps } from "redux/data/portalMapSlice";
import { fetchUserInfo } from "redux/data/userInfoSlice";
import { fetchPortalerServers } from "redux/data/portalerServerSlice";
import { fetchSimpleZones } from "redux/data/simpleZoneSlice";
import { fetchFullZones } from "redux/data/fullZoneSlice";
import axios from "axios";

const MainPage = () => {
  const dispatch = useAppDispatch()
  const isZonesFetchedRef = useRef<boolean>(false)
  const tickerRef = useRef<number>();

  useEffect(() => {
    isZonesFetchedRef.current = false
    tickerRef.current = setInterval(function f() {
      if (axios.defaults.headers.common["Authorization"]) {
        if (!isZonesFetchedRef.current) {
          dispatch(fetchSimpleZones())
          dispatch(fetchFullZones())
          isZonesFetchedRef.current = true
        }
        dispatch(fetchPortalerServers()).then(_ => {
          dispatch(fetchUserInfo())
          dispatch(fetchPortalMaps())
        })
      }
      return f
    }(), 10000, [])
    return () => clearInterval(tickerRef.current)
  }, [dispatch]);

  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const sidebarTabs = [
    {
      key: "0",
      icon: LocationPlusIcon,
      iconSelected: LocationPlusFilledIcon,
      component: <NewConnectionComponent />,
      accessRoles: ["edit"]
    },
    {
      key: "1",
      icon: ArrowsToCenterIcon,
      iconSelected: ArrowsToCenterIcon,
      component: <PathfindingComponent />,
      accessRoles: ["read"]
    },
    {
      key: "2",
      icon: CircleInfoIcon,
      iconSelected: CircleInfoFilledIcon,
      component: <InformationComponent />,
      accessRoles: ["read"]
    },
    {
      key: "5",
      icon: TrophyIcon,
      iconSelected: TrophyFilledIcon,
      component: <PortalerCreatorsComponent />,
      accessRoles: ["read"]
    },
    {
      key: "6",
      icon: CircleQuestionIcon,
      iconSelected: CircleQuestionFilledIcon,
      component: <HelpComponent />,
      accessRoles: ["read"]
    },
    {
      key: "7",
      icon: CogIcon,
      iconSelected: CogFilledIcon,
      component: <SettingsComponent />,
      accessRoles: ["read"]
    }
  ].filter(tab => ["0", "2", "6", "7"].includes(tab.key))
    .map((value, index) => {
      value.key = index.toString();
      return value;
    });
  const [selectedSidebarTab, setSelectedSidebarTab] = useState(
    sidebarTabs[0]
  );

  const simpleZonesIsFirstTimeLoading = useAppSelector(state => state.simpleZone.firstTimeLoading)
  const fullZonesIsFirstTimeLoading = useAppSelector(state => state.fullZone.firstTimeLoading)
  const portalMapIsFirstTimeLoading = useAppSelector(state => state.portalMap.firstTimeLoading)
  const userInfoIsFirstTimeLoading = useAppSelector(state => state.userInfo.firstTimeLoading)

  return <>{
    simpleZonesIsFirstTimeLoading || fullZonesIsFirstTimeLoading ||
    portalMapIsFirstTimeLoading || userInfoIsFirstTimeLoading ? null :
    <Layout>
      <Layout.Sider
        className={styles.layoutSidebar}
        width={414}
        collapsedWidth={74}
        collapsible
        defaultCollapsed={false}
        collapsed={siderCollapsed}
        trigger={null}
        style={{
          transition: "all 0.17s linear",
          backgroundColor: `#18191C`
        }}
      >
        <SidebarComponent
          onCollapsedChange={() => setSiderCollapsed(!siderCollapsed)}
          sidebarCollapsed={siderCollapsed}
          onSelectedTabChange={(tabIdx: number) => setSelectedSidebarTab(sidebarTabs[tabIdx])}
          sidebarTabs={sidebarTabs}
          selectedSidebarTab={selectedSidebarTab}
        />
      </Layout.Sider>
      <Layout className={styles.layoutWithoutSidebar}>

        <Layout.Header className={styles.layoutHeader}
                       style={{ width: siderCollapsed ? "calc(100% - 74px)" : "calc(100% - 413px)" }}>
          <HeaderComponent />
        </Layout.Header>


        <Layout.Content className={styles.layoutContent}>
          <PortalMapComponent />
        </Layout.Content>


        <Layout.Footer className={styles.layoutFooter}>
          <FooterComponent />
        </Layout.Footer>
      </Layout>
    </Layout>
  }</>;
};

export default MainPage;
