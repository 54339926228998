import React from "react";
import styles from "./style.module.scss";
import { useAppSelector } from "redux/hooks";

const UserBlock = () => {
  const portalerUserMe = useAppSelector(state => state.userInfo.user)

  if (!portalerUserMe) return <div />;
  return (
    <div className={styles.wrapper}>
      <div style={{ padding: "12px 8px", display: "flex", flexDirection: "row" }}>
        <img
          src={portalerUserMe.avatarURL}
          style={{ borderRadius: "50%" }}
          width={40}
          height={40}
          alt={portalerUserMe.username}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft: "10px"
          }}
        >
          <span className={`${styles.dataWrapper} ${styles.nameColor}`}>
            {portalerUserMe.nickname} <br />
          </span>
          <span className={`${styles.dataWrapper} ${styles.incrementorColor}`}>
            {portalerUserMe.username}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserBlock;
