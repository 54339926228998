import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { SimpleZone } from "./simpleZoneSlice";
import { RootState } from "redux/store";
import { FullZone } from "redux/data/fullZoneSlice";
import { resetReduxStore } from "redux/hooks";

export type PortalMap = {
  _id: string
  serverId: string
  name: string
  mapType: "PUBLIC" | "PRIVATE_BASIC" | "PRIVATE_VIP" | "DEV" | "UNDEFINED"
  albionServer: "WEST" | "EAST" | "UNDEFINED"
  accessRoles: {
    READ: string[]
    EDIT: string[]
  }
  portalConnections: PortalConnection[]
}

export type PortalConnection = {
  portalId: string
  userId: string
  info: PortalConnectionInfo
  creationDate: Date
}

export type PortalConnectionInfo = {
  fromZone: SimpleZone
  toZone: SimpleZone
  portalType: PortalType
  expiringDate?: Date
}

export type PortalType = "BLUE" | "YELLOW" | "STATIC_TEMP" | "STATIC_CONST" | "UNDEFINED"

type InitialState = {
  loading: boolean
  firstTimeLoading: boolean
  lastUpdate: number
  portalMaps: PortalMap[]
  selectedPortalMap?: PortalMap
  selectedPortalConnection?: PortalConnection
  selectedFullZone?: FullZone
  portalMapForceUpdate: boolean
  centerOnZone?: FullZone
  error: string
}

const initialState: InitialState = {
  loading: false,
  firstTimeLoading: true,
  lastUpdate: new Date().getTime(),
  portalMaps: [],
  selectedPortalMap: undefined,
  selectedPortalConnection: undefined,
  selectedFullZone: undefined,
  portalMapForceUpdate: true,
  centerOnZone: undefined,
  error: ""
};

export const fetchPortalMaps = createAsyncThunk("map/fetchPortalMaps", async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState;

  if (!state.portalerServer.selectedPortalerServer) return rejectWithValue("No server selected");

  const response = await axios
    .get(`/api/map/list/${state.portalerServer.selectedPortalerServer._id}?mergeWithPublic=${state.userInfo.enableSyncWithPublicMap}`);
  return response.data;
});

export const createPortalConnection = createAsyncThunk("map/createPortalConnection", async (data: { portalConnectionInfo: PortalConnectionInfo }, { getState }) => {
  const state = getState() as RootState;
  const response = await axios.post(`/api/map/${state.portalMap.selectedPortalMap?._id}/create`,
    data.portalConnectionInfo
  )
  return response.data;
});

export const updatePortalConnection = createAsyncThunk("map/updatePortalConnection", async (data: { portalId: string, portalConnectionInfo: PortalConnectionInfo }, { getState }) => {
  const state = getState() as RootState;
  const response = await axios.post(`/api/map/${state.portalMap.selectedPortalMap?._id}/update`,
    {
      portalId: data.portalId,
      info: data.portalConnectionInfo
    }
  )
  return response.data;
})

export const deletePortalConnection = createAsyncThunk("map/deletePortalConnection", async (data: { portalId: string }, { getState }) => {
  const state = getState() as RootState;
  const response = await axios.post(`/api/map/${state.portalMap.selectedPortalMap?._id}/delete`, {
    "portalId": data.portalId
  })
  return response.data;
})

const portalMapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    selectPortalMapById: (state, action: PayloadAction<string>) => {
      state.selectedPortalMap = state.portalMaps[state.portalMaps.map(it => it._id).indexOf(action.payload)];
    },
    setSelectedPortalConnection: (state, action: PayloadAction<PortalConnection | undefined>) => {
      state.selectedPortalConnection = action.payload;
    },
    setSelectedFullZone: (state, action: PayloadAction<FullZone | undefined>) => {
      state.selectedFullZone = action.payload;
    },
    setMapForceUpdate: (state, action: PayloadAction<boolean>) => {
      state.portalMapForceUpdate = action.payload;
    },
    setCenterOnZone: (state, action: PayloadAction<FullZone | undefined>) => {
      state.centerOnZone = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchPortalMaps.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      fetchPortalMaps.fulfilled,
      (state, action: PayloadAction<PortalMap[]>) => {
        state.loading = false;
        state.firstTimeLoading = false;
        state.portalMaps = action.payload;
        const oldMap = state.selectedPortalMap;
        let newMap = state.selectedPortalMap;
        if (state.portalMaps.findIndex(v => v._id === newMap?._id) === -1) newMap = undefined
        if (!newMap) {
          newMap = state.portalMaps.length === 0 ? undefined : state.portalMaps[0];
        } else {
          const mapFromReq = state.portalMaps.find(v => v._id === newMap!!._id)!!;
          if (JSON.stringify(mapFromReq) !== JSON.stringify(newMap)) newMap = mapFromReq;
        }


        if (newMap === undefined) {
          state.selectedPortalMap = undefined
        } else if (oldMap === undefined) {
          state.selectedPortalMap = newMap
        } else if (
          oldMap._id === newMap._id &&
          oldMap.serverId === newMap.serverId &&
          oldMap.name === newMap.name &&
          oldMap.mapType === newMap.mapType &&
          oldMap.albionServer === newMap.albionServer &&
          JSON.stringify(oldMap.accessRoles) === JSON.stringify(newMap.accessRoles)
        ) {
          const oldMapConnections = JSON.stringify(oldMap.portalConnections)
          const newMapConnections = JSON.stringify(newMap.portalConnections)
          if (oldMapConnections !== newMapConnections) {
            oldMap.portalConnections = newMap.portalConnections
          }
        } else {
          state.selectedPortalMap = newMap
          state.portalMapForceUpdate = true
        }

        state.error = "";
      }
    );
    builder.addCase(fetchPortalMaps.rejected, (state, action) => {
      state.loading = false;
      state.portalMaps = [];
      state.selectedPortalMap = undefined;
      state.portalMapForceUpdate = false;
      state.selectedPortalConnection = undefined;
      state.selectedFullZone = undefined;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(resetReduxStore, _ => initialState);
  }
});

export default portalMapSlice.reducer;
export const {
  selectPortalMapById,
  setSelectedPortalConnection,
  setMapForceUpdate,
  setSelectedFullZone,
  setCenterOnZone,
} = portalMapSlice.actions;