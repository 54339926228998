import { ConfigProvider } from "antd";

import React, { ReactElement, useEffect, } from "react";
import DiscordInvitePage from "pages/DiscordInvitePage";
import AuthPage from "pages/AuthPage";
import MainPage from "pages/MainPage";
import { useAuthHeader, useIsAuthenticated } from "react-auth-kit";
import { resetReduxStore, useAppDispatch, useAppSelector } from "redux/hooks";
import axios from "axios";
import { fetchPortalerServers } from "redux/data/portalerServerSlice";
import { fetchPortalMaps } from "redux/data/portalMapSlice";
import { fetchUserInfo } from "redux/data/userInfoSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { appVersion } from "./index";
import { fetchIsBackendUp, setAppVersion } from "./redux/data/appGlobalSlice";
import ServerDown from "./pages/ServerDown";

export const reloadPortalerData = (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(fetchPortalerServers()).then(_ => {
    dispatch(fetchUserInfo())
    dispatch(fetchPortalMaps())
  })
}

export const App = () => {
  const isAuthed = useIsAuthenticated();
  const authHeader = useAuthHeader();
  const dispatch = useAppDispatch()
  const reduxAppVersion = useAppSelector(state => state.appGlobal.appVersion)

  useEffect(() => {
    if (isAuthed()) {
      axios.defaults.headers.common["Authorization"] = authHeader()
      dispatch(fetchPortalerServers()).catch(() => {
        console.log("Failed to fetch portaler servers")
        delete axios.defaults.headers.common["Authorization"]
      })
    } else {
      delete axios.defaults.headers.common["Authorization"]
    }
  }, [isAuthed])

  useEffect(() => {
    if (reduxAppVersion !== appVersion) {
      dispatch(setAppVersion(appVersion))
      dispatch(resetReduxStore())
    }
  }, []);

  const ServerUpRoute = (props: { element: ReactElement, fallback: ReactElement }) => {
    const isBackendUp = useAppSelector(state => state.appGlobal.isBackendUp)

    useEffect(() => {
      dispatch(fetchIsBackendUp());
    }, []);

    return isBackendUp === undefined || isBackendUp === null ? null : isBackendUp ? props.element : props.fallback
  }

  const ProtectedRoute = (props: { element: ReactElement, fallback: ReactElement } ) => {
    return isAuthed() ? props.element : props.fallback
  }

  const HasPortalerServersRoute = (props: { element: ReactElement; fallback: ReactElement }) => {
    const portalerServersIsFirstTimeLoading = useAppSelector((state) => state.portalerServer.firstTimeLoading);
    const selectedPortalerServer = useAppSelector((state) => state.portalerServer.selectedPortalerServer);
    const errorMsg = useAppSelector((state) => state.portalerServer.error);

    if (portalerServersIsFirstTimeLoading) return null;
    if (errorMsg !== "") return null;
    if (!selectedPortalerServer) return props.fallback;

    return props.element;
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorSuccess: "#00b912",
          colorError: "#ea4949",
          colorWarning: "#ea4949",
          colorInfo: "#f7f7f7",
          colorTextBase: "#f7f7f7",
          colorBgBase: "#18191c",
          colorBgSpotlight: "#4c4c4c",
          colorPrimaryBg: "#262729",
          colorPrimaryBgHover: "#262729",
          colorPrimaryBorder: "",
          colorPrimaryBorderHover: "",
          colorPrimary: "#d52f2f",
          colorPrimaryTextHover: "#f7f7f7",
          colorPrimaryText: "#f7f7f7",
          colorPrimaryTextActive: "#f7f7f7",
          borderRadius: 8,
          wireframe: true,
          colorBgContainer: "#262729",
          colorBgElevated: "#262729",
          colorBgLayout: "#262729",
          colorBorder: "#262729",
          colorBorderSecondary: "#262729",
          fontSize: 16
        }
      }}
    >
      <ServerUpRoute
        fallback={<ServerDown />}
        element={
          <ProtectedRoute
            fallback={<AuthPage />}
            element={<HasPortalerServersRoute
              fallback={<DiscordInvitePage />}
              element={<MainPage />}
            />}
          />
        }
      />
    </ConfigProvider>
  );
};