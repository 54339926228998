import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { App } from "App";
import { AuthProvider } from "react-auth-kit";
import { persistor, store } from "redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

export const appVersion = "0.1.16"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/*<BrowserRouter>*/}
        <AuthProvider
          authType={"cookie"}
          authName={"_auth"}
          cookieDomain={window.location.hostname}
          cookieSecure={window.location.protocol === "https:"}
        >
          <App />
        </AuthProvider>
      {/*</BrowserRouter>*/}
    </PersistGate>
  </Provider>
);

