import React, { useEffect } from "react";
import styles from "./style.module.scss";

import { useSignIn } from "react-auth-kit";
import StyledButton from "../../sharedComponents/StyledButton";
import { CircleQuestionIcon, DiscordLogoIcon, PortalerLogoTextIcon } from "../../assets";
import JustText from "../../sharedComponents/JustText";
import FooterComponent from "sharedComponents/FooterComponent";

const AuthPage = () => {
  const signIn = useSignIn()

  useEffect(() => {
    const token = new URL(window.location).searchParams.get("token")
    let userId; try { userId = window.atob(token?.split(".")[0]) } catch (e) { userId = null }
    console.log(`Auth Effect: TokenUserID is ${userId}`)
    if (token != null) {
      if(signIn({
        token: token,
        tokenType: "Bearer",
        expiresIn: 86400,
        authState: { "id": userId }
      })) {
        console.log("Auth Effect: SignIn Success")
        window.location = "/"
      } else {
        console.log("Auth Effect: SignIn Failed")
      }
    }
  }, [signIn]);


  return (<>{new URL(window.location).searchParams.get("token") ? null :
      <div className={styles.background}>
        <div className={styles.backgroundForm}>
          <img src={PortalerLogoTextIcon} alt="" />

          <div className={styles.innerBlock}>
            <JustText textSize={"big"} isBold={true}>Login with</JustText>
            <StyledButton icon={DiscordLogoIcon} label={"Discord"} centerContent onClick={() =>
              fetch("/api/auth/login?redirect_uri=" + window.location)
                .then(res => res.json())
                .then(data => window.location = data.redirect_uri)
            } />
          </div>

          <div className={styles.innerBlock}>
            <img src={CircleQuestionIcon} alt="" style={{ marginBottom: "10px" }} />
            <span>
              We use cookies to ensure that we give you the best experience on our website.
            </span>
          </div>
        </div>
        <div className={styles.layoutFooter}>
          <FooterComponent />
        </div>
      </div>
    }</>
  );
};

export default AuthPage;
