import React, { useState } from "react";

import styles from "./style.module.scss";
import { ArrowRotateRightIcon, DeleteOutlineIcon, HomeIcon, MapIcon, SearchIcon } from "assets";
import StyledSelect from "sharedComponents/StyledSelect";
import StyledButton from "sharedComponents/StyledButton";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  deletePortalConnection,
  selectPortalMapById,
  setCenterOnZone,
  setMapForceUpdate,
  setSelectedPortalConnection
} from "redux/data/portalMapSlice";
import { reloadPortalerData } from "App";

const HeaderComponent = () => {
  const fullZones = useAppSelector(state => state.fullZone.fullZones);
  const portalMaps = useAppSelector(state => state.portalMap.portalMaps);
  const portalMap = useAppSelector(state => state.portalMap.selectedPortalMap)
  const [selectedButton, setSelectedButton] = useState<number>();
  const selectedPortalConnection = useAppSelector(state => state.portalMap.selectedPortalConnection);
  const portalerUserMe = useAppSelector(state => state.userInfo.user!!);
  const selectedPortalMap = useAppSelector(state => state.portalMap.selectedPortalMap);
  const homeLocation = useAppSelector(state => state.userInfo.homeLocation);
  const dispatch = useAppDispatch()


  return (
    <div className={styles.headerWrapper}>
      {[
        {
          id: 0,
          icon: DeleteOutlineIcon,
          component: undefined,
          visible: selectedPortalConnection != null && portalMap !== undefined && portalerUserMe !== null && portalerUserMe.roles.some(value => portalMap.accessRoles.EDIT.includes(value)),
          onClick: () => {
            if (selectedPortalConnection && portalMap) {
              dispatch(deletePortalConnection({
                portalId: selectedPortalConnection.portalId
              }))
              dispatch(setSelectedPortalConnection(undefined));
              reloadPortalerData(dispatch)
            }
          }
        },
        {
          id: 1,
          icon: HomeIcon,
          component: undefined,
          visible: portalMaps.length > 0,
          onClick: () => dispatch(setCenterOnZone(homeLocation))
        },
        {
          id: 2,
          icon: MapIcon,
          component: <StyledSelect
            key={"topButtonComponent-2"}
            width={"200px"}
            data={portalMaps.map(portalMap => portalMap.name)}
            defaultValue={portalMap?.name}
            value={portalMap?.name}
            onChange={value => portalMaps.forEach(pM => {
              if (pM.name === value) {
                dispatch(selectPortalMapById(pM._id));
                dispatch(setMapForceUpdate(true))
              }
            })}
            alternativeStyle
          />,
          visible: portalMaps.length > 0,
          onClick: () => setSelectedButton(2)
        },
        {
          id: 3,
          icon: SearchIcon,
          component: <StyledSelect
            key={"topButtonComponent-3"}
            width={"200px"}
            data={(() => {
              let zonesOnMap = new Set<string>()
              selectedPortalMap?.portalConnections.forEach(portalConnection => {
                zonesOnMap.add(portalConnection.info.fromZone.name);
                zonesOnMap.add(portalConnection.info.toZone.name);
              });
              return Array.from(zonesOnMap);
            })()}
            defaultHint={"Select Zone"}
            onChange={value => {
              dispatch(setCenterOnZone(fullZones.find(fullZone => fullZone.name === value)!!))
              setSelectedButton(undefined);
            }}
            enableClear
            enableSearch
            alternativeStyle
          />,
          visible: portalMaps.length > 0,
          onClick: () => setSelectedButton(3)
        },
        {
          id: 4,
          icon: ArrowRotateRightIcon,
          component: undefined,
          visible: portalMaps.length > 0,
          onClick: () => dispatch(setMapForceUpdate(true))
        }
      ].map((button, index) => {
        if (!button.visible) return null;
        if (selectedButton === button.id && button.component) return button.component;

        return <StyledButton
          key={`topButton-${index}`}
          icon={button.icon}
          headerButton
          onClick={button.onClick}
        />;
      })}
    </div>
  );
};

export default HeaderComponent;
